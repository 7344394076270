import React from "react";

const Footer = () => {
  return (
    <footer className=" h-24 p-8 ">
      <div className="flex flex-col md:flex-row items-center justify-between gap-2">
        <div className="text-white z-20">
          Contact Us at{" "}
          <a href="mailto: info.vanshatech@gmail.com">
            info.vanshatech@gmail.com
          </a>
        </div>
        <div className="text-white">
          Vanshatech © 2024 | All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
