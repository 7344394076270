import React from "react";
import { BackgroundLines } from "../@/components/ui/background-lines";

const ComingSoon = () => {
  return (
    <>
      <h2 className=" text-left ml-2 text-white text-2xl md:text-lg lg:text-2xl font-sans  font-bold ">
        VANSHATECH
        <br />
      </h2>{" "}
      <BackgroundLines className="flex h-screen items-center justify-center w-full flex-col px-4">
        <h2 className="bg-clip-text text-transparent text-center bg-gradient-to-b  from-neutral-600 to-white text-4xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-20 font-bold tracking-tight">
          Coming Soon
          <br />
        </h2>
        <p className="max-w-xl mx-auto text-sm md:text-lg font-sans bg-clip-text text-transparent bg-gradient-to-t from-neutral-600 to-white text-center pb-16">
          Empowering Growth with Cutting-Edge Software & AI Solutions
        </p>
      </BackgroundLines>
    </>
  );
};

export default ComingSoon;
